import React, { useState } from 'react'
import { graphql, withPrefix } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import DefaultLayout from '../layouts/DefaultLayout'
import DataList from '../components/DataList'
import LoadMoreButton from '../common/LoadMoreButton'
import Date from '../components/Date'
import ReactHtmlParser from 'react-html-parser'

const ReportsPage = ({ data }) => {
  const { t } = useTranslation()

  const { paginatedCollectionPage } = data
  const { nodes } = paginatedCollectionPage

  const [loading, setLoading] = useState(false)
  const [latestPage, setLatestPage] = useState(paginatedCollectionPage)
  const [posts, setPosts] = useState(nodes)

  const handlePagination = async (e) => {
    e.preventDefault()
    setLoading(true)

    const collectionId = latestPage.collection.id
    const nextPageId = latestPage.nextPage.id
    const url = withPrefix(`/pagination/${collectionId}/${nextPageId}.json`)
    const data = await fetch(url).then((response) => response.json())

    setPosts((posts) => [...posts, ...data.nodes])
    setLatestPage(data)
    setLoading(false)
  }

  return (
    <DefaultLayout>
      <DataList
        className="my-24"
        data={posts}
        titleComponent={() => {
          return (
            <h3 className="flex justify-between text-green-theme font-bold text-2xl xl:text-4xl">
              <span>{t('reports')}</span>
            </h3>
          )
        }}
        articleTemplate={({ title, link, date, excerpt, language }) => {
          return (
            <>
              <div className="text-gray-500">
                <Date locale={language.code.toLowerCase()}>{date}</Date>
              </div>
              <h2 className="text-xl xl:text-2xl font-bold mb-5 hover:underline">
                <Link to={link} dangerouslySetInnerHTML={{ __html: title }} />
              </h2>
              <div className="text-lg font-normal">
                {ReactHtmlParser(excerpt)}
              </div>
            </>
          )
        }}
      />

      {latestPage.hasNextPage && (
        <div className="flex justify-center">
          <LoadMoreButton onClick={handlePagination} loading={loading} />
        </div>
      )}
    </DefaultLayout>
  )
}

export const query = graphql`
  query paginatedCollectionPage($collection: String) {
    paginatedCollectionPage(
      collection: { name: { eq: $collection } }
      index: { eq: 0 }
    ) {
      nodes
      hasNextPage
      nextPage {
        id
      }
      collection {
        id
      }
    }
  }
`

export default ReportsPage
